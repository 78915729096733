import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, fertilizer, transport) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        // pdfContent.push({ text: vm.$t('movement.office_copy'), style: 'fertilizer', alignment: 'center' })
        var prgoDate = vm.$n(data.program_no)
        prgoDate += ','
        prgoDate += dateFormat(data.allocation_date_program)
        var driverInfo = data.driver_name
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: [
                [
                  { text: vm.$t('movement.ChalanNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: data.lori_challan_no, style: 'td' },
                  { text: vm.$t('movement.date'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: dateFormat(data.allocation_date), style: 'td' }
                ],
                [
                  { text: vm.$t('movement.sender'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: vm.$i18n.locale === 'en' ? data.office_name_en : data.office_name_bn, style: 'td' },
                  { text: vm.$t('movement.lorryTruckLoadingSpace'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: '', style: 'td' }
                ],
                [
                  { text: vm.$t('movement.CNFName'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.cnf_agent_id_bn : data.cnf_agent_id, style: 'td' },
                  { text: vm.$t('movement.LorryTruckLeavingTime'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: '', style: 'td' }
                ],
                [
                  { text: vm.$t('movement.contactPhoneNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.cnf_agent_mobile, { useGrouping: false }), style: 'td' },
                  {},
                  {},
                  {}
                ],
                [
                  { text: vm.$t('movement.receiver'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.sale_center_bn : data.sale_center + '(' + vm.$i18n.locale === 'bn' ? data.godown_infos_bn : data.godown_infos + ')', style: 'td' },
                  { text: vm.$t('movement.driverNameAddress'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: driverInfo, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.vesselsName'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.ship_name_bn : data.ship_name, style: 'td' },
                  {},
                  {},
                  {}
                ],
                [
                  { text: vm.$t('movement.ProgramNoDate'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: prgoDate, style: 'td' },
                  {},
                  {},
                  {}
                ],
                [
                  { text: vm.$t('movement.lorryTruckNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: data.vehicle_no, style: 'td' },
                  {},
                  {},
                  {}
                ],
                [
                  { text: vm.$t('movement.OwnerNameNAddress'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: '....', style: 'td' },
                  { text: vm.$t('movement.OwnerPhoneNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.transport_mobile, { useGrouping: false }), style: 'td' }
                ],
                [
                  { text: vm.$t('movement.phoneNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.phone_no, { useGrouping: false }), style: 'td' },
                  { text: vm.$t('movement.licenseNo'), style: 'td' },
                  { text: ':', style: 'td' },
                  { text: '', style: 'td' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: '', style: 'fertilizers', alignment: 'center' })
        pdfContent.push({ text: vm.$t('movement.DetailsItemInfo'), style: 'details', alignment: 'left', bold: true })
        pdfContent.push({ text: '', style: 'fertilizers', alignment: 'center' })
        pdfContent.push(
            {
              table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*'],
                body: [
                  [
                    { text: vm.$t('movement.fertilizerName'), style: 'th', alignment: 'center' },
                    { text: vm.$t('movement.numberOfBag'), style: 'th', alignment: 'right' },
                    { text: vm.$t('movement.quantity'), style: 'th', alignment: 'right' }
                  ],
                  [
                    { text: (i18n.locale === 'bn') ? data.fertilizer_name_bn : data.fertilizer_name, style: 'td', alignment: 'center' },
                    { text: vm.$n(data.total_allocated_amount * 20, { useGrouping: false }), style: 'td', alignment: 'right' },
                    { text: vm.$n(data.total_allocated_amount, { useGrouping: false }), style: 'td', alignment: 'right' }
                  ]
                ]
              }
            }
          )
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        pdfContent.push({ text: vm.$t('movement.inWords') + ' : ' + (vm.currentLocale === 'en' ? vm.convertNumberToEnglishWords(data.total_allocated_amount) : vm.convertNumberToBanglaWords(data.total_allocated_amount)) + ' ' + vm.$t('movement.metric_ton'), style: 'details', alignment: 'left', bold: true })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' },
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' },
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' },
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' }
                ],
                [
                  { text: vm.$t('movement.driverSignature'), style: 'td', alignment: 'center' },
                  { text: vm.$t('movement.BADCSignSeal'), style: 'td', alignment: 'center' },
                  { text: vm.$t('movement.CNFSignatureSeal'), style: 'td', alignment: 'center' },
                  { text: vm.$t('movement.receiverSignatureSeal'), style: 'td', alignment: 'center' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: '', style: 'tableBelow', alignment: 'center' })
        pdfContent.push(
          {
            table: {
              style: 'tableBelow',
              headerRows: 0,
              widths: ['35%', '25%', '7%', '*'],
              body: [
                [
                  { text: vm.$t('movement.letter_of_acknowledgement'), style: 'th', alignment: 'center', colSpan: 4 },
                  { },
                  { },
                  { }
                ],
                [
                  { text: vm.$t('movement.ChalanNo') + ' : ' + data.lori_challan_no, style: 'td', alignment: 'center', colSpan: 4 },
                  { },
                  { },
                  { }
                ],
                [
                  { text: vm.$t('movement.receipt_stock_book_page_no'), style: 'td', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'left' },
                  { text: vm.$t('globalTrans.date'), style: 'td', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('movement.amount_of_receipt_fertilizer'), style: 'td', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'left' },
                  { text: vm.$t('globalTrans.date'), style: 'td', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('movement.amount_of_discrepancy'), style: 'td', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'left', colSpan: 3 }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: '', style: 'tableBelow', alignment: 'center' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*'],
              body: [
                [
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' },
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' },
                  { text: '_______________________', style: 'signatureTh', alignment: 'center' }
                ],
                [
                  { text: vm.$t('movement.super_officer_signature_seal'), style: 'td', alignment: 'center' },
                  { text: vm.$t('movement.deputy_assistant_director_signature_seal'), style: 'td', alignment: 'center' },
                  { text: vm.$t('movement.assistant_director_signature_seal'), style: 'td', alignment: 'center' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [1, 1, 1, 1]
          },
          signatureTh: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [1, 30, 1, 1]
          },
          fertilizers: {
            margin: [5, 5, 5, 5]
          },
          details: {
            margin: [5, 5, 5, 0]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            margin: [0, -5, 0, 5],
            alignment: 'center'
          },
          fertilizer: {
              margin: [0, 1, 0, 1]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          transport: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          tableBelow: {
            margin: [0, 20, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('carying-agent-wise-final-program')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
