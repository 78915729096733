<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                        <b-col lg="12">
                          <div class="card">
                            <div class="card-inside-title font-weight-bold text-capitalize text-center p-1 m-2 bg-light text-black">
                              {{ $t('movement.carryingAgentwiseFinalProgram') }}
                            </div>
                        </div>
                        </b-col>
                      </b-row>
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No" vid="program_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="program_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    v-model="formData.program_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    @keyup="getProgramData(formData.program_no)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Warehouse Name"  vid="godown_infos_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="godown_infos_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.warehouseName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.godown_infos_id"
                                  :options="warehouseList"
                                  id="godown_infos_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="District"  vid="district_id" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('org_pro_district.district')}}
                                </template>
                                <b-form-input
                                  readonly
                                  v-model="formData.district_name"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-input>
                                <!-- <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div> -->
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="LC No"  vid="lc_no">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('warehouse_config.region')}}
                                </template>
                                <b-form-input
                                readonly
                                    id="lc_no"
                                    v-model="formData.region_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <!-- <div class="invalid-feedback">
                                  {{ errors[0] }} lcErrorMsg
                                </div> -->
                                <div class="invalid-feedback d-block" v-if="lcErrorMsg">
                                  {{ lcErrorMsg }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                              <ValidationProvider name="Transport Agent"  vid="transport_agent_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transport_agent_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.transport_agent')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.transport_agent_id"
                                  :options="agentList"
                                  id="transport_agent_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="amount"
                            >
                              <template v-slot:label>
                                {{$t('movement.transferAbleAmount')}}
                              </template>
                              <b-form-input
                                  id="amount"
                                  v-model="formData.amount"
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="transferable_bag" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transferable_bag"
                              >
                                <template v-slot:label>
                                  {{$t('movement.transferableBlag')}}
                                </template>
                                <b-form-input
                                    id="transferable_bag"
                                    v-model="formData.amount_of_bag"
                                    readonly
                                ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                              <b-thead>
                                <b-tr>
                                  <b-th>
                                    {{$t('fertilizerConfig.godown_information.capacity_in_metric_ton')}}
                                  </b-th>
                                  <b-th>
                                    {{$t('movement.godownStock')}}
                                  </b-th>
                                  <b-th>
                                    {{$t('movement.fer_shortage_cpatacipty')}}
                                  </b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <b-tr>
                                  <b-td>
                                    {{ godownData.capacity_of_godown }}
                                  </b-td>
                                  <b-td>
                                    {{ godownData.stock_fertilizer }}
                                  </b-td>
                                  <b-td>
                                    {{ godownData.carring_fertilizer }}
                                  </b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12">
                          <div class="card">
                          <div class="card-inside-title font-weight-bold text-capitalize text-center p-1 m-2 bg-light text-black">
                            {{ $t('movement.loriInformation') }}
                          </div>
                        </div>
                        </b-col>
                      </b-row>
                      <b-table-simple class="mt-3" hover borderless small caption-top responsive>
                        <!-- <b-thead head-variant="secondary">
                          <b-tr>
                            <b-th>{{ $t('globalTrans.vehicle_no') }}</b-th>
                            <b-th>Name of Ports</b-th>
                            <b-th>Allocated Amount</b-th>
                          </b-tr>
                        </b-thead> -->
                        <b-tbody>
                          <b-tr>
                            <b-th>
                            <ValidationProvider name="Vehicle No"  vid="vehicle_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="vehicle_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.vehicle_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="vehicle_no"
                                    v-model="formData.vehicle_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-th>
                            <b-th>
                              <ValidationProvider name="Driver Name"  vid="driver_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="driver_name"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.driverName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="driver_name"
                                    v-model="formData.driver_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-th>
                            <b-th>
                              <ValidationProvider name="Phone No"  vid="phone_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="phone_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.phone_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="phone_no"
                                    @input="acceptNumber"
                                    type="number"
                                    v-model="formData.phone_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-th>
                          </b-tr>
                          <b-tr>
                            <b-th>
                            <ValidationProvider name="Amount"  vid="total_allocated_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="total_allocated_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.amountOfFertilizer')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="total_allocated_amount"
                                    v-model="formData.total_allocated_amount"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  @keyup="multiplier"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-th>
                            <b-th>
                              <ValidationProvider name="Amount of Bag"  vid="amount_of_bag" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="amount_of_bag"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.amountOfBag')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="amount_of_bag"
                                    v-model="formData.bag_amount"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-th>
                            <b-th>
                            </b-th>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { carryingAgentFinalProTransferAgent, carryingAgentFinalProWise, carryingAgentFinalProStore, carryingAgentFinalProUpdate, godownInformationDetails } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.formData.bag_amount = this.formData.total_allocated_amount * 20
      this.getProgramData(this.formData.program_no)
      this.setTransferableAmount(this.formData.transport_agent_id)
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        total_allocated_amount: '',
        current_available_amount: '',
        godown_infos_id: 0,
        district_id: 0,
        district_name: '',
        region_name: '',
        program_no: '',
        transport_agent_id: 0,
        p_id: '',
        amount: '',
        bag_amount: '',
        amount_of_bag: '',
        fertilizer_id: '',
        fiscal_year_id: ''
      },
      godownData: {
        capacity_of_godown: 0,
        stock_fertilizer: 0,
        carring_fertilizer: 0
      },
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      lcErrorMsg: '',
      warehouseList: [],
      districtList: [],
      agentList: []
    }
  },
  computed: {
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.godown_infos_id': function (newVal, oldVal) {
      this.districtList = this.getGodownInfo(newVal)
      this.getGodownInfoDetails()
    },
    'formData.transport_agent_id': function (newVal, oldVal) {
      this.districtList = this.setTransferableAmount(newVal)
    }
  },
  methods: {
    multiplier (event) {
      const id = '#' + event.target.id
      const idValAmount = document.querySelector(id).value
      if (idValAmount > this.formData.amount) {
        const remainingIdVal = Math.floor(idValAmount / 10)
        document.querySelector(id).value = remainingIdVal
        this.formData.bag_amount = idValAmount * 20
      } else {
        this.formData.bag_amount = idValAmount * 20
      }
    },
    acceptNumber () {
      var x = this.formData.phone_no.replace(/\D/g, '').match(/^(01[356789])(\d{8})$/)
        if (!x) {
            this.formData.phone_no = ''
        }
    },
    setTransferableAmount (agentId) {
      var ids = 0
      if (this.id) {
        ids = this.formData.porgram_id
      } else {
        ids = this.formData.p_id
      }
      const params = Object.assign({}, { program_id: ids, program_no: this.formData.program_no, transport_agent_id: this.formData.transport_agent_id, godown_infos_id: this.formData.godown_infos_id })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProTransferAgent, params).then(response => {
          this.formData.amount = response.data.rest_amount
          this.formData.amount_of_bag = response.data.rest_amount * 20
      })
    },
    getGodownInfo (godownId) {
      const data = this.godownInfoList.find(doc => doc.value === godownId)
      this.formData.district_name = this.getDataById(data.district_id, 'district')
      this.formData.region_name = this.getDataById(data.region_id, 'region')
      this.formData.office_id = data.region_id
      const districtObject = this.$store.state.commonObj.districtList.find(item => item.value === data.district_id)
      this.formData.district = districtObject !== 'undefined' ? districtObject.text_en : ''
    },
      getDataById (Id, type) {
        if (Id) {
          var data
          switch (type) {
            case 'fertilizer':
              data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
              break
            case 'agent':
              data = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.value === Id)
              break
            case 'godown':
              data = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.value === Id)
              break
            case 'district':
              data = this.$store.state.commonObj.districtList.find(item => item.value === Id)
              break
            case 'region':
              data = this.$store.state.commonObj.officeList.find(item => item.value === Id)
              break
            default:
              break
          }
          if (data) {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
          } else {
            return null
          }
        } else {
          return null
        }
      },
    getAgentData (transportAgentId) {
      const params = Object.assign({}, { program_name: transportAgentId })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProTransferAgent, params).then(response => {
        if (response.success) {
          }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getGodownInfoDetails () {
      const params = {
        godown_infos_id: this.formData.godown_infos_id
      }
      RestApi.getData(seedFertilizerServiceBaseUrl, godownInformationDetails, params).then(response => {
        if (response.success) {
          this.godownData.capacity_of_godown = response.godownInfo.capacity
          this.godownData.stock_fertilizer = response.currentStock
          this.godownData.carring_fertilizer = response.carryingAgent
        } else {
          this.godownData.capacity_of_godown = 0
          this.godownData.stock_fertilizer = 0
          this.godownData.carring_fertilizer = 0
        }
      })
    },
    getProgramData (programNo) {
      const params = Object.assign({}, { program_name: programNo })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, carryingAgentFinalProWise, params).then(response => {
        if (response.success) {
          this.formData.p_id = response.data.program.p_id
          this.formData.fertilizer_id = response.data.program.fertilizer_id
          this.formData.fiscal_year_id = response.data.program.fiscal_year_id
          this.formData.lc_no = response.data.program.lc_no
          const tmpData = this.$store.state.commonObj.fiscalYearList.find(item => item.value === response.data.program.fiscal_year_id)
          this.formData.fiscal_year = tmpData.text_en
          this.warehouseList = response.data.godowns.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.name_bn, value: item.id }
            } else {
              return { text: item.name, value: item.id }
            }
          })
          this.agentList = response.data.agents.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.company_agent_name_bn, value: item.id }
            } else {
              return { text: item.company_agent_name, value: item.id }
            }
          })
          }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
       return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      // const tmpData1 = this.$store.state.commonObj.districtList.find(item => item.value === this.formData.district_id)
      // this.formData.district_name = tmpData1.text_en
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${carryingAgentFinalProUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, carryingAgentFinalProStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
        this.loading = false
      }
    }
  }
}
</script>
